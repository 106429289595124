// composables/shiny-button.js
import { ref, onMounted, onBeforeUnmount } from 'vue';

export function useShinyButton() {
  const shinyButton = ref(null);

  const handleMouseMove = (e) => {
    const { x, y } = shinyButton.value.getBoundingClientRect();
    shinyButton.value.style.setProperty("--x", e.clientX - x);
    shinyButton.value.style.setProperty("--y", e.clientY - y);
  };

  onMounted(() => {

    if (shinyButton.value) {
      shinyButton.value.addEventListener("mousemove", handleMouseMove);
    }
    
  });

  onBeforeUnmount(() => {
    if (shinyButton.value) {
      shinyButton.value.removeEventListener("mousemove", handleMouseMove);
    }
  });

  return { shinyButton };
}
